import React from 'react'

import PaypalExpressBtn from 'react-paypal-express-checkout';

class AuditSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isPayed: false, isMounted: false};
  }

  componentDidMount() {
    this.setState({isMounted: true});
  }

  render() {
    const onSuccess = (payment) => {
      console.log("The payment was succeeded!", payment);
      // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
      this.setState({isPayed: true});
    }
    const onCancel = (data) => {
        // User pressed "cancel" or close Paypal's popup!
        console.log('The payment was cancelled!', data);
        // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
    }
    const onError = (err) => {
        // The main Paypal's script cannot be loaded or somethings block the loading of that script!
        console.log("Error!", err);
        alert("There was error processing your payment. Please try again later or send us an email at info@thealphadex.com");
        // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
        // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
    }
    let env = 'production'; // you can set here to 'production' for production
    let currency = 'USD'; // or you can set this value from your props or state
    let total = 5; // same as above, this is the total amount (based on currency) to be paid by using Paypal express checkout
    // Document on Paypal's currency code: https://developer.paypal.com/docs/classic/api/currency_codes/

    const client = {
        sandbox:    'AeDtJ7qLP3IM0RTzixw7hQW1RyTXF2urSMfc9gj2WhKAXD7iLRUFWLyKEdYraRr-nxcEACcG4aVR4Gd-',
        production: 'AUfcA-JV5sLEA_3My4ZYranWDgrZk4m88Qp525aCVauE_elR5Sr7x5L0NXrzvR_TQzLxpIaNBsEkQMD1',
    }
    // In order to get production's app-ID, you will have to send your app to Paypal for approval first
    // For sandbox app-ID (after logging into your developer account, please locate the "REST API apps" section, click "Create App"):
    //   => https://developer.paypal.com/docs/classic/lifecycle/sb_credentials/
    // For production app-ID:
    //   => https://developer.paypal.com/docs/classic/lifecycle/goingLive/

    if (this.state.isPayed) {
      return(
        <div className='audit-banner' style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          paddingTop: 30,
        }}>
          <p style={{fontWeight: 'bold',textAlign: 'center',width: '100%'}}>Set up a time for your Account Audit here:</p>
          <a href="https://calendly.com/amzluke" target="_blank" rel="noopener noreferrer" style={{fontWeight: 'bold'}}>Set Up a Call</a>
        </div>
      )
      
    } else if(!this.state.isPayed && this.state.isMounted) {
      return (
        <div className='audit-banner' style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          paddingTop: 30,
        }}>
          <p style={{fontWeight: 'bold',textAlign: 'center',width: '100%', marginBottom: 15}}>Want a personalized reccomendation of which tools/services would serve you best? <br/>Set up an account audit for only $5</p>
          <PaypalExpressBtn env={env} client={client} currency={currency} total={total} onError={onError} onSuccess={onSuccess} onCancel={onCancel} />
          <p style={{textAlign: 'center',width: '100%', fontSize: 14, marginBottom: 0}}>If you need to change your scheduled call please send an email to info@thealphadex.com</p>
        </div>
      );
    } else {
      return(
        <div className='audit-banner' style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          paddingTop: 30,
        }}>
          <p style={{fontWeight: 'bold',textAlign: 'center',width: '100%', marginBottom: 15}}>Want a personalized reccomendation of which tools/services would serve you best? <br/>Set up an account audit for only $5</p>
          
          <p style={{textAlign: 'center',width: '100%', fontSize: 14, marginBottom: 0}}>If you need to change your scheduled call please send an email to info@thealphadex.com</p>
        </div>
      )
    }
  }
}


export default ({ data }) => {

  return (
    <AuditSignup />
  )
  
}
