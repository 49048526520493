import React from 'react'
import { Link } from 'gatsby'

import './categoryContainer.css'

const CategoryContainer = ({ children, title, emoji, linkUrl }) => (
  <div
    className='category-container'
  >
    <Link 
      to={linkUrl} 
      style={{
        textDecoration: 'none',
        color: 'inherit',
        borderBottom: '2px solid',
        display: 'block',
        margin: '0 0 10px 0',
        padding: '0 0 10px 0',
      }}>
      <span role="img" aria-label={title} style={{ 
        fontSize: 85,
        display: 'block',
        lineHeight: 1.2,
        textAlign: 'center'
      }}>{emoji}</span>
      <h2 className="title" style={{margin : 0, textAlign: 'center'}}>{title}</h2>
    </Link>
    
  </div>
)

export default CategoryContainer
