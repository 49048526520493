import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import CategoryContainer from '../components/categoryContainer'
import HeroBgImg from '../components/heroBgImg'
import AuditBanner from '../components/auditBanner'

import './front-page.css'
import './amazon-tool-bundles.css'

var ReactRotatingText = require('react-rotating-text');

export default ({ data }) => {
  console.log(data);
  return(
  <div id="front-page">
    <Layout>

      <HeroBgImg imgUrl={data.heroBG.childImageSharp.fluid}>
        <h1 style={{textAlign: 'center'}}>Find the best <br/><ReactRotatingText items={['agencies', 'software', 'freelancers', 'courses', 'meetups', 'podcasts']} cursor={false} emptyPause={200} style={{color: '#ff9900',}} /><br/> for selling on Amazon.</h1>
      </HeroBgImg>
      
      <AuditBanner />
      
      <div className="categories">
        <CategoryContainer
          title={'Agencies'}
          emoji={'🏢'}
          linkUrl={'/agencies/'}
        />

        <CategoryContainer
          title={'Software'}
          emoji={'👾'}
          linkUrl={'/software/'}
        />
          
        
        <CategoryContainer
          title={'Freelancers'}
          emoji={'👩‍💻'}
          linkUrl={'/freelancers/'}
        />
        

        <CategoryContainer
          title={'Courses'}
          emoji={'🎓'}
          linkUrl={'/courses/'}
        />
        

        <CategoryContainer
          title={'Conferences/Meetups'}
          emoji={'🤝'}
          linkUrl={'/conferences-and-meetups/'}
        />
        

        <CategoryContainer
          title={'Podcasts'}
          emoji={'🎙'}
          linkUrl={'/podcasts/'}
        />
        

      </div>
    </Layout>
  </div>
  )
}

export const query = graphql`
  {
    heroBG: file(relativePath: {eq: "hero-bg.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  
`
